'use client';

import { useEffect, useState } from 'react';

import type { Nullable } from 'corgi-types';
import { Locale } from 'corgi-types';
import CommonDisclaimer from 'corgi/common-disclaimer/components/CommonDisclaimer';
import CommonVisualNavigation from 'corgi/common-visual-navigation/components/CommonVisualNavigation';
import { Grid, GridItem } from 'corgi/common/components/Grid';
import { Locale as CorgiLocale } from 'corgi/common/enums/Locale';
import { useAppDomain } from 'corgi/common/providers/AppDomainProvider';
import { useLocalization } from 'corgi/common/providers/LocaleProvider';
import GlobalHead from 'corgi/global-head/components/GlobalHead';
import type { GlobalNavigationProps } from 'frontend-utils';

import ErrorPageStyles from '@components/common/ErrorPageStyles';
import { LogoutBanner } from '@components/common/fragments/AutoLogoutBanner';
import Footer from '@components/common/fragments/Footer';
import Header from '@components/common/fragments/Header';
import notFoundPageFixture from '../../static/common-not-found-error-page.json';
import visualNavigationStatic from '../../static/visual-navigation.json';

const useLocale = () => {
  const { locale: corgiLocale } = useLocalization();
  return (corgiLocale === CorgiLocale.De_De_H24 ? Locale.DE_DE : corgiLocale) as Locale;
};

export default function NotFound() {
  const locale = useLocale();
  const appDomain = useAppDomain();
  const [hasError, setHasError] = useState(false);
  const [globalNavProps, setGlobalNavProps] = useState<Nullable<GlobalNavigationProps>>(null);

  useEffect(() => {
    if (globalNavProps) {
      return;
    }

    import(`../../static/global-navigation/${locale}.json`)
      .then(data => setGlobalNavProps(data))
      .catch(() => setHasError(true));
  }, [globalNavProps, locale]);

  const { notFound } = notFoundPageFixture[locale];
  const visualNavigationProps = visualNavigationStatic[locale];

  if (!globalNavProps) {
    return hasError ? 'Internal server error' : null;
  }

  return (
    <>
      <GlobalHead baseUrl={appDomain} />

      <LogoutBanner />
      <Header {...globalNavProps} />
      <CommonDisclaimer />

      <ErrorPageStyles />
      <section id="error">
        <p>{notFound}</p>
      </section>

      <Grid>
        <GridItem>
          <CommonVisualNavigation {...visualNavigationProps} />
        </GridItem>
      </Grid>

      <Footer {...globalNavProps} />
    </>
  );
}
